<template>
    <div>
        <div v-if="multiple">
            <span>{{ label }}</span>
            <v-chip-group column>
                <v-chip
                    close
                    v-for="item in multipleItems"
                    :key="item.value"
                    @click:close="removeItem(item)"
                >
                    {{ item.text }}
                </v-chip>
                <v-chip @click="popup" color="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-chip>
            </v-chip-group>
        </div>
        <div v-else>
            <v-text-field
                :value="singleItem.text"
                :label="label"
                clearable
                @click:clear="clear"
                append-outer-icon="mdi-database-search"
                @click:append-outer="popup"
                readonly
                filled
                dense
                outlined
            ></v-text-field>
        </div>
        <v-dialog v-model="dialogPopup" max-width="800px">
            <v-card class="search-dialog pa-3">
                <v-card-title>Selezione {{ label }}</v-card-title>
                <v-card-text>
                    <v-data-table
                        class="elevation-1"
                        :headers="headers"
                        :search="search"
                        :items="items"
                        @click:row="choose"
                        dense
                    >
                        <template #top>
                            <div class="mx-2">
                                <v-text-field v-model="search" label="cerca..."></v-text-field>
                            </div>
                        </template>
                        <template #item.checkbox="{item}">
                            <v-simple-checkbox disabled :value="isSelected(item)"></v-simple-checkbox>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="primary" @click="dialogPopup=false">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        url: String,
        value: [Number, Object, Array],
        label: String,
        filters: Object,
    },
    data: () => ({
        search: '',
        singleItem: {
            label: '',
            value: null
        },
        multipleItems: [],
        items: [],
        dialogPopup: false
    }),
    mounted() {
        this.check(this.value);
    },
    watch: {
        async search() {
            await this.do_search();
        },
        value(v) {
            this.check(v);
        },
    },
    computed: {
        headers() {
            if (this.multiple)
                return [
                    {value: 'checkbox', sortable: false, text: 'Selezione', width: 100, align: 'center'},
                    {value: 'text', sortable: false, text: 'Elemento'}
                ];
            else
                return [{value: 'text', sortable: false, text: 'Seleziona un elemento'}];
        }
    },
    methods: {
        getId(value) {
            if (Number.isInteger(value)) {
                return value;
            } else if (Object.keys(value).includes('id')) {
                return value.id;
            }
        },
        check(v) {
            if (v == null || v.length === 0) {
                this.clear(true);
                return;
            }
            if (this.multiple) {
                this.setupMultiple(v);
            } else {
                this.setupSingle(this.getId(v));
            }
        },
        async setupSingle(id) {
            const res = await this.$http.get(this.url, {id});
            if (res.ok) {
                this.singleItem = res.result.data[0];
            }
        },
        async setupMultiple(items) {
            const ids = items.map(row => this.getId(row)).join(',');
            const res = await this.$http.get(this.url, {ids});
            if (res.ok) {
                this.multipleItems = res.result.data;
            }
        },
        clear(init) {
            this.multipleItems = [];
            this.singleItem = {label: '', value: null};
            if (init !== true)
                this.emitValue();
        },
        async do_search() {
            const params = {search: this.search, ...this.filters};
            const res = await this.$http.get(this.url, params);
            if (res.ok) {
                this.items = res.result.data;
            }
        },
        async popup() {
            this.search = '';
            await this.do_search();
            this.dialogPopup = true;
        },
        choose(item) {
            if (this.multiple) {
                if (this.isSelected(item)) {
                    this.removeItem(item);
                } else {
                    this.multipleItems.push(item);
                }
            } else {
                this.singleItem = item;
                this.dialogPopup = false;
            }
            this.emitValue();
        },
        removeItem(item) {
            const index = this.multipleItems.map(row => row.value).indexOf(item.value);
            this.multipleItems.splice(index, 1);
            this.emitValue();
        },
        emitValue() {
            if (this.multiple)
                this.$emit('input', this.multipleItems.map(row => row.value));
            else
                this.$emit('input', this.singleItem.value);
            this.$emit('change');
        },
        isSelected(item) {
            return this.multipleItems.map(row => row.value).includes(item.value);
        }
    }
}
</script>
